import React from 'react';
import SEO from '../components/seo'

import Layout from '../components/Layout';
import { graphql } from 'gatsby'
import RichContent from '../components/RichContent';
import BulletPoints from '../components/BulletPoints';
import { makeTitle } from '../functions/Utils'
import Header from '../components/Header';

const About = ({data}) => {
  const { contentfulPage } = data

  const one = contentfulPage.content[0]
  const person = contentfulPage.content[1]

  return (
<Layout fullMenu backgroundImage={data.contentfulPage.image}>
    <SEO title={makeTitle(one.header)} />
    <article id={"main"} >
      <Header 
        className="about"
        style={{ backgroundPosition: '50% -40vh',}}
        image={contentfulPage.image ? contentfulPage.image.file.url : ""}
      >
        <h2>{one.header}</h2>
      </Header>
      <section className="wrapper style5">
        <div className="inner">

          
          <h3>{person.name}</h3>
          <RichContent content={person.childContentfulPersonDescriptionRichTextNode.json} />

          <hr />
          <div className="row ">
            <div className="col-7-medium col-12-small col-7">
              <RichContent content={person.childContentfulPersonCareerRichTextNode.json} />

            </div>

            <div className="col-5-medium col-12-small col-5">

              { React.Children.toArray(person.bullets.map(bullet => <BulletPoints {...bullet} />))}

            </div>
          </div>
        </div>
      </section>
    </article>
  </Layout>
  )
}

export const query = graphql`
query AboutPageQuery {
  contentfulPage(url: {eq: "/about"}) {
    id
    name
    image {
      file {
        url
      }
      title
    }
    content {
      ... on ContentfulPerson {
        id
        name
        bullets {
          bullets
          header
        }
        career {
          career
          json
        }
        childContentfulPersonCareerRichTextNode {
          json
        }
        childContentfulPersonDescriptionRichTextNode {
          json
        }
        description {
          json
        }
      }
      ... on ContentfulSection {
        id
        name
        header
        subHeader
      }
    }
  }
}

`

export default About;
