import React from 'react'

const BulletPoints = props => {
    const { header, bullets } = props; 
    return (
        <>
        <h2>{header}</h2>
        <ul>
            {React.Children.toArray(bullets.map(bullet =>  <li>{bullet}</li>  ))}
        </ul>
        </>
    )
}


export default BulletPoints
